<template>
    <div class="bg-default w-full h-full flex flex-col items-center justify-center">
        <div class="max-w-7xl mx-auto mb-8">
            <div class="md:flex md:justify-between">
                <div class="flex-1 min-w-0">
                    <!-- Profile -->
                    <div class="flex items-center">
                        <div>
                            <div class="flex items-center">
                                <h1 class="ml-3 text-2xl font-bold leading-7 text-gray-100 sm:leading-9">
                                    Welcome back, {{ $me.getUser().name }}.
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <v-section-group class="grid grid-cols-1 gap-default">
            <template v-for="(menuItem, menuItemIndex) in menu">
                <router-link v-if="menuItem.visible" :key="'menuItem_' + menuItemIndex" :to="menuItem.route">
                    <v-card>
                        <v-card-content>
                            <div class="">
                                <h3 class="text-lg font-medium text-gray-500">
                                    <span class="focus:outline-none text-gray-300 group-hover:text-primary-500">
                                        <i :class="menuItem.icon" class="text-lg text-primary-500 mr-2" />
                                        <!-- Extend touch target to entire panel -->
                                        <span class="absolute inset-0" aria-hidden="true" />
                                        {{ menuItem.label }}
                                    </span>
                                </h3>

                                <p class="mt-2 text-sm text-gray-400">
                                    {{ menuItem.description }}
                                </p>
                            </div>
                        </v-card-content>
                    </v-card>
                </router-link>
            </template>
        </v-section-group>
    </div>
</template>

<script>

export default {
    computed: {
        appName() {
            return process.env.VUE_APP_NAME ?? "";
        },
        menu() {
            return [
                {
                    label: "Orders",
                    icon: "fa fa-list-alt",
                    description: "List of orders that are currently in the system.",
                    route: { name: "orders.index" },
                    visible: this.$me.hasPermission("orders.read"),
                },
                {
                    label: "Jobs",
                    icon: "fa fa-briefcase",
                    description: "List of jobs that are currently in the system.",
                    route: { name: "jobs.index" },
                    visible: this.$me.hasPermission("order-lines.read"),
                },
                {
                    label: "Payouts",
                    icon: "fa fa-credit-card",
                    description: "List of payouts from completed jobs in the  system.",
                    route: { name: "payouts.index" },
                    visible: this.$me.hasPermission("payouts.read"),
                },
                {
                    label: "Conversations",
                    icon: "fas fa-comments",
                    description: `Enter a live chat with ${this.appName}.`,
                    route: { name: "conversations.index" },
                    visible: this.$me.hasPermission("conversations.read"),
                },
                {
                    label: "My Profile",
                    icon: "fa fa-user-circle",
                    description: "Manage your account information and settings.",
                    route: { name: "me.profile" },
                    visible: true,
                },
            ];
        },
        visibleMenuCount() {
            return this.menu.filter((item) => item.visible === true).length;
        },
    },
    created() {
        this.$store.commit("appModule/setMenuItems", this.menu);
    },
    beforeDestroy() {
        this.$store.commit("appModule/setMenuItems", []);
    },
};
</script>
